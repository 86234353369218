.background{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00A1E9;
  height: auto;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-color: white;
}

.logoBox{
  display: flex;
  width: 95%;
  height: 30vh;
  margin-bottom: 5vh;
}

.logo{
  width: 50%;
  background-image: url('../assets/images/logo_image.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logoText{
  width: 50%;
  background-image: url('../assets/images/logo_text.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



.downloadButtons{
  display: flex;
  height: 8vh;
  z-index: 0;
  margin-bottom: 3vh;
}

.appstore{
  width: 200px;
  height: 100%;
  background-image: url('../assets/images/apple-badge.png');
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.playstore{
  width: 200px;
  height: 100%;
  background-image: url('../assets/images/google-play-badge.png');
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}




.intro{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: rgb(70, 70, 70);
}

.block{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3vh 0;
}

.balloon{
  width:max-content;
  background-color:#00A1E9;
  color: white;
  padding: 16px 32px;
  border-radius: 32px;
  position: relative;
}

.balloon::after{
  border-top:0px solid transparent; 
  border-left: 10px solid transparent; 
  border-right: 10px solid transparent; 
  border-bottom: 10px solid #00A1E9; 
  content:""; 
  position:absolute;
  top:-10px;
  left:50px; 
}

.bold{
  font-weight: 800;
  font-size: 17px;
  color: black;
}

.extraBold{
  font-weight: 800;
  font-size: 17px;
  color: #00A1E9;
}

.floor{
  width: 100%;
  height: 10vh;
  margin-top: 5vh;
  background-color: rgb(230, 230, 230);
  display: flex;
  align-items: center;
  justify-content: center;
}





.modalBackground{
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 40%;
  border-radius: 20px;
}

.modalTitle{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5vh;
}

.closeButtonBox{
  display: flex;
  width: 100%;
  justify-content: end;
}



@media (max-width: 900px) {
  .container{
    width: 100%;
  }

  .modal{
    width: 90%;
  }

  .logoBox{
    margin-bottom: 0;
  }
}